import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-xl/"
            }}>{`All-Stars XL`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-weekly/"
            }}>{`All-Stars Weekly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tax/"
            }}>{`All-Stars Tax-Efficient`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: meta-portfolio`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks, bonds, commodities, VIX Futures`}</li>
        <li parentName="ul">{`Rebalancing schedule: daily`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $30,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`All-Stars Total Return`}</em>{` aims to continually outperform the balanced 60/40 benchmark while reducing volatility and drawdowns. The meta-portfolio combines three proprietary strategies, implementing momentum, mean-reversion, and volatility-targeting trading styles, leading to additional diversification benefits. However, `}<em parentName="p">{`All-Stars Total Return`}</em>{` rebalances daily, resulting in higher-than-average maintenance requirements. In summary, this strategy portfolio appeals to determined investors focusing on balanced returns. `}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, All-Stars Total Return: A low-volatility meta-strategy",
                "title": "TuringTrader.com, All-Stars Total Return: A low-volatility meta-strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/all-stars-portfolios/"
            }}>{`All-Stars Portfolios`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`All-Stars Total Return`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`divide capital into three equal-sized tranches`}</li>
        <li parentName="ul">{`allocate one tranche each to TuringTrader's `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round-Robin`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-mean-kitty/"
          }}>{`Mean Kitty`}</a></li>
        <li parentName="ul">{`rebalance between tranches once per month`}</li>
      </ul>
      <p>{`By combining three portfolios, `}<em parentName="p">{`All-Stars Total Return`}</em>{` diversifies across investment styles. As a result, the meta-strategy achieves higher risk-adjusted returns than the individual components.`}</p>
      <p>{`The previous version of this portfolio used TuringTrader's Stocks on the Loose as the primary driver of returns. We replaced Stocks on the Loose with Round-Robin for the current version because the latter offers better returns at a lower risk.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`All-Stars Total Return`}</em>{` typically invests about 60% of its capital in the U.S. Stock Market. The remaining capital is allocated to various ETFs tracking bonds, gold, commodities, and VIX Futures.`}</p>
      <p><em parentName="p">{`All-Stars Total Return`}</em>{` combines momentum, mean-reversion, and volatility-targeting strategies in a single portfolio. In addition to this diversification across trading styles, the meta-portfolio invests in various asset classes and markets. As a result, `}<em parentName="p">{`All-Stars Total Return`}</em>{` significantly reduces the portfolio's concentration risk while improving the ability to cope with a broad range of market conditions.`}</p>
      <p>{`The improved diversification shows through the portfolio's low standard deviation of returns, beta, and Ulcer index. In addition, the Monte-Carlo simulation illustrates how `}<em parentName="p">{`All-Stars Total Return`}</em>{` not only greatly reduces the downside risk but also narrows the range of possible outcomes compared to the 60/40 benchmark.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`All-Stars Total Return`}</em>{` handily beats the 60/40 benchmark in most years. Further, when contemplating the entire economic cycle, the strategy beats the S&P 500 by a wide margin.`}</p>
      <p>{`The rolling returns illustrate how the portfolio consistently outperforms its benchmark, with only very brief periods of underperformance or negative returns. In addition, the tracking chart shows how the portfolio continually gains over its benchmark and how these gains are further augmented during economic downturns.`}</p>
      <p>{`Overall, `}<em parentName="p">{`All-Stars Total Return`}</em>{` delivers smooth returns at very low volatility and serves as the Swiss Army Knife of portfolios, addressing a wide range of investment objectives.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`All-Stars Total Return`}</em>{` trades frequently and regularly triggers taxable events. As a result, investors should expect almost all capital gains to be short-term. Therefore, the strategy works best in tax-deferred accounts.`}</p>
      <p>{`To allow for proper position sizing, `}<em parentName="p">{`All-Stars Total Return`}</em>{` requires a minimum investment of $30,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, July 2020`}</a>{`: Initial release, combining `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{`, `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{`, and `}<a parentName="li" {...{
            "href": "/portfolios/tt-mean-kitty/"
          }}>{`Mean Kitty`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, November 2020`}</a>{`: Replaced `}<a parentName="li" {...{
            "href": "/portfolios/tt-stocks-on-the-loose/"
          }}>{`Stocks on the Loose`}</a>{` with `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{` for higher returns and lower capital requirements.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, April 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{` to v3 for improved performance in environments of rising interest rates.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=4"
          }}>{`v4, October 2022`}</a>{`: Upgraded `}<a parentName="li" {...{
            "href": "/portfolios/tt-round-robin"
          }}>{`Round Robin`}</a>{` and `}<a parentName="li" {...{
            "href": "/portfolios/tt-mean-kitty/"
          }}>{`Mean Kitty`}</a>{` to their latest versions for improved performance in bear markets.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      